import { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';
import { fetchCards } from '../utils/api';


export interface CardType {
    id: number;
    name: string;
    created_at: string;
    category: string;
    url: string;
}

interface CardsState {
    cards: CardType[];
}

const initialState: CardsState = {
    cards: [],
};

const authReducer = (state: typeof initialState, action: any) => {
    switch (action.type) {
        case 'SET_CARDS':
            return { ...state, cards: action.payload };
        default:
            return state;
    }
};

interface CardsContextType {
    cards: CardType[];
}

const CardsContext = createContext<CardsContextType | null>(null);

export const CardsProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const asyncFetchCards = async () => {
            if (state.cards.length === 0) {
                const cardsResponse = await fetchCards();
                dispatch({ type: 'SET_CARDS', payload: cardsResponse });
            }
        }
        asyncFetchCards();
    }, [state.cards]);

    return (
        <CardsContext.Provider value={{ ...state, }}>
            {children}
        </CardsContext.Provider>
    );
};

export const useCards = () => {
    const context = useContext(CardsContext);
    if (!context) {
        throw new Error('useCards must be used within an CardsProvider');
    }
    return context;
};