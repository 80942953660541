import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCards } from '../../utils/api';

const CardDetails: React.FC = () => {
  const { cardId } = useParams<{ cardId: string }>();
  const [recipient, setRecipient] = useState('');
  const [collectMoney, setCollectMoney] = useState(false);
  const [card, setCard] = useState<any | null>(null);

  const handleRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipient(e.target.value);
  };

  const handleCollectMoneyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCollectMoney(e.target.checked);
  };


  useEffect(() => {
    const asyncFetchCard = async () => {
      try {
        const cardsResponse = await fetchCards();
        const foundCard = cardsResponse.find((c: any) => c.id === Number(cardId));
        setCard(foundCard);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };
    asyncFetchCard();
  }, [cardId]);


  if (!card) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-4 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h1 className="text-2xl font-bold text-center"> {card.name}</h1>
      <div className="flex justify-center items-center ">
        <img src={card.url} alt={card.name} className="w-[250px] h-auto rounded-md" />
      </div>
      <form className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="recipient" className="font-semibold">Who is this card for?</label>
          <input
            type="text"
            id="recipient"
            value={recipient}
            onChange={handleRecipientChange}
            placeholder="Enter recipient's name"
            className="mt-1 p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="collectMoney"
            checked={collectMoney}
            onChange={handleCollectMoneyChange}
            className="mr-2"
          />
          <label htmlFor="collectMoney" className="font-semibold">Add for money collection</label>
        </div>
      </form>
    </div>
  );
};

export default CardDetails;