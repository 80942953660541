import { useState } from 'react';
import { useAuth } from '../../context/authContext';
import AuthForm from './AuthForm';
import Logo from "../../assets/images/otter.webp";

export default function Account() {
    const [email, setEmail] = useState('');
    const { user, signIn, signOut, awaitingSignIn } = useAuth();

    const handleSignIn = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await signIn(email);
        } catch (error) {
            console.error('Error signing in:', error);
            alert('Error signing in. Please try again.');
        }
    };

    const handleSignOut = () => {
        signOut();
        alert('You have been signed out.');
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-[#F0FFFF]">
            <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
                <div className="flex justify-center">
                    <img src={Logo} alt="eCard Otter Logo" width={56} height={54} />
                </div>
                <h1 className="text-2xl font-semibold mb-6 text-center">E-card Otter Account</h1>
                {awaitingSignIn ? (
                    <AwaitingSignIn email={email} />
                ) : (
                    <AuthForm
                        user={user}
                        email={email}
                        handleSignIn={handleSignIn}
                        handleSignOut={handleSignOut}
                        setEmail={setEmail}
                    />
                )}
            </div>
        </div>
    );
}

function AwaitingSignIn({ email }: { email: string }) {
    return (
        <div>
            <p className="text-md font-medium text-center">Check your email for a sign-in link! Email: {email}</p>
        </div>
    );
}