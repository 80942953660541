import { AuthProvider } from '../context/authContext';
import { CardsProvider } from '../context/cardsContext';
import { ReactNode } from 'react';

export function Providers({ children }: { children: ReactNode }) {
  return (
    <CardsProvider>
      <AuthProvider>{children}</AuthProvider>
    </CardsProvider>
  );
}