import axios from 'axios';

export const fetchCards = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/cards`);
    return response.data;
  } catch (error) {
    console.error('Error fetching cards:', error);
    throw error;
  }
};

export const fetchCard = async (cardId: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/cards/${cardId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching card:', error);
    throw error;
  }
};
