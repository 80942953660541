import Logo from "../assets/images/otter.webp";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <div className="w-full h-15 sticky top-0 z-50 border-b border-b-[#a9a9a9] bg-[#F0FFFF]">
        <div className="flex justify-between items-center h-full px-6">
          <div className="flex justify-between items-center">
            <img src={Logo} alt="eCard Otter Logo" width={56} height={54} />
            <p>
            </p>
          </div>
          <ul className="hidden md:flex gap-x-6 text-black">
            <li>
              <a>About Us</a>
            </li>
            <li>
              <a>Services</a>
            </li>
            <Link to="/account">
              Account
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
