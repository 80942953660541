import Tilt from 'react-parallax-tilt';
import type { CardType } from '../../context/cardsContext';

export default function Card({ card }: { card: CardType }) {
  return (
    <Tilt glareEnable glarePosition='all' className='rounded flex border-2 border-black'>
      <img
        src={card.url}
        alt={card.name}
        className='rounded shadow-lg object-cover w-[225px]'
      />
    </Tilt>
  );
}
