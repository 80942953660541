import Grid from "./components/cards/grid/Grid";
import { useState } from "react";

export default function Home() {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const categories = [
    "Birthday",
    "Anniversary",
    "Wedding",
    "New Baby",
    "Get Well Soon",
    "Thank You",
    "Congratulations",
    "Christmas",
    "Hanukkah",
    "Easter",
    "Valentine's Day",
    "Mother's Day",
    "Father's Day",
    "Thanksgiving"
  ];

  return (
    <main className="flex flex-col min-h-screen items-center px-24 mt-16 gap-12">
      <div className="flex flex-col gap-2 items-center">
        <h1 className="text-4xl">E-card Otter</h1>
        <h3 id="subtitle" className="text-lg">
          The best place to find the perfect e-card for any occasion
        </h3>
      </div>
      <div className="flex flex-wrap gap-2 justify-center items-center">
        {categories.map((category, index) => (
          <button
            key={index}
            className="rounded-md p-2"
            onClick={() => setSelectedCategory(category.toLowerCase())}
          >
            {" "}
            {category}
          </button>
        ))}
      </div>
      <Grid category={selectedCategory} />
    </main>
  );
}
