import { useCards } from '../../../context/cardsContext';
import GridCell from './GridCell';
// import LeftArrow from './LeftArrow';
// import RightArrow from './RightArrow';
import { useNavigate } from 'react-router-dom';

interface GridProps {
    category?: string | null;
}

export default function Grid({ category }: GridProps) {
    const navigate = useNavigate();
    const { cards } = useCards();

    if (!cards) {
        return null;
    };

    const handleCardClick = (cardId: number) => {
        navigate(`/card/${cardId}`);
    };

    const filteredCards = category ? cards.filter(card => card.category === category) : cards;

    return (
        <div className='flex flex-col items-center'>
            <div className="grid grid-cols-3 gap-24">
                {
                    filteredCards.map((card, index) => (
                        <div className='flex items-start gap-4 hover:cursor-pointer' key={index} onClick={() => handleCardClick(card.id)}>
                            <GridCell index={index} card={card} />
                        </div>
                    ))
                }
            </div>
            {/* <div className="flex mt-16">
                <LeftArrow currentPage={currentPage} setCurrentPage={setCurrentPage} />
                <RightArrow setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} />
            </div> */}
        </div>
    )
}
