import { createContext, useContext, useReducer, ReactNode, useEffect } from 'react';
import { supabaseSignOut, signInWithEmail, supabase } from '../utils/supabase';

export interface User {
    email: string;
    id: string;
}

interface AuthState {
    user: User | undefined;
    awaitingSignIn: boolean;
}
// TODO: Add the AuthAction and AwaitingSignInAction types and see if we need to update the authReducer function.
// interface AuthAction {
//     type: 'SIGN_IN' | 'SIGN_OUT';
//     payload?: User;
// }

// interface AwaitingSignInAction {
//     type: 'AWAITING_SIGN_IN';
//     payload: string;
// }

const initialState: AuthState = {
    user: undefined,
    awaitingSignIn: false,
};

const authReducer = (state: typeof initialState, action: any) => {
    switch (action.type) {
        case 'AWAITING_SIGN_IN':
            return { ...state, awaitingSignIn: true };
        case 'SIGN_IN':
            return { ...state, user: action.payload, awaitingSignIn: false };
        case 'SIGN_OUT':
            return { ...state, user: null, awaitingSignIn: false };
        default:
            return state;
    }
};

interface AuthContextType {
    user: User | null;
    signIn: (email: string) => Promise<void>;
    signOut: () => void;
    awaitingSignIn: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        async function fetchUserSession() {
            const sessionResponse = await supabase.auth.getSession(); // only secure on the client side : https://supabase.com/docs/reference/javascript/auth-getuser
            const sessionUser = sessionResponse.data.session?.user;
            return sessionUser;
        }

        // Mark this function as async
        (async () => {
            if (!state.user) {
                const user = await fetchUserSession();
                if (user && user.email) {
                    dispatch({ type: 'SIGN_IN', payload: { email: user.email, id: user.id } });
                }
            }
        })();
    }, [state.user]);

    const signIn = async (email: string) => {
        try {
            await signInWithEmail(email);
            dispatch({ type: 'AWAITING_SIGN_IN', payload: email });
        } catch (error) {
            console.error('Error signing in:', error);
            alert(`Error signing in: ${error}`);
        }
    };

    const signOut = () => {
        supabaseSignOut();
        dispatch({ type: 'SIGN_OUT' });
    };

    return (
        <AuthContext.Provider value={{ ...state, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};