import { User } from "../../context/authContext";

interface AuthFormProps {
    user: User | null;
    email: string;
    handleSignIn: (event: React.FormEvent) => void;
    handleSignOut: () => void;
    setEmail: (email: string) => void;
}

const AuthForm: React.FC<AuthFormProps> = ({ user, email, handleSignIn, handleSignOut, setEmail }) => {
    return (
        <>

            {user?.email ? (
                <div>
                    <p>Welcome, hello</p>
                    <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={handleSignOut}>Sign Out</button>
                </div>
            ) : (
                <form onSubmit={handleSignIn} className='flex flex-col'>
                    <label htmlFor="email" className="mb-1 block text-md font-medium text-gray-700">Email:</label>
                    {!user?.email && <p className="mb-1 block text-sm font-med text-gray-700">(Sign up / log in with your email!)</p>}
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="mb-4  block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Sign Up / Log In</button>
                </form>
            )}
        </>
    );
};

export default AuthForm;