import { motion } from 'framer-motion';
import Card from '../Card';
import type { CardType } from '../../../context/cardsContext';
interface GridCellProps {
    index: number;
    card: CardType;
}

export default function GridCell({ index, card }: GridCellProps) {
    return (
        <>
            <motion.div
                whileHover={{
                    y: -10,
                }}
                transition={{
                    type: 'spring',
                    stiffness: 300,
                }}
            >
                <Card card={card} />
            </motion.div>
        </>

    )
}
