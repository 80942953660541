import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navbar from './components/Navbar';
import Account from './components/account';
import { Providers } from './utils/providers';
import CardDetails from './components/cards/CardDetails';
import NotFoundPage from './components/NotFoundPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <>
      <Navbar />
      <App />
    </>,

  },
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/card/:cardId",
    element: <>
      <Navbar />
      <CardDetails />
    </>
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
