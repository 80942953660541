import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-6 text-center">
                <div className="flex justify-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="256"
                        height="256"
                        viewBox="0 0 256 256"
                        className="w-32 h-32 text-gray-400"
                    >
                        <g transform="translate(1.41 1.41) scale(2.81 2.81)">
                            <path
                                d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 4 C 22.393 4 4 22.393 4 45 s 18.393 41 41 41 s 41 -18.393 41 -41 S 67.607 4 45 4 z"
                                fill="currentColor"
                            />
                            <circle cx="30.344" cy="33.274" r="5.864" fill="currentColor" />
                            <circle cx="59.664" cy="33.274" r="5.864" fill="currentColor" />
                            <path
                                d="M 72.181 65.49 c -0.445 0 -0.893 -0.147 -1.265 -0.451 c -7.296 -5.961 -16.5 -9.244 -25.916 -9.244 c -9.417 0 -18.62 3.283 -25.916 9.244 c -0.854 0.7 -2.115 0.572 -2.814 -0.283 c -0.699 -0.855 -0.572 -2.115 0.283 -2.814 C 24.561 55.398 34.664 51.795 45 51.795 c 10.336 0 20.438 3.604 28.447 10.146 c 0.855 0.699 0.982 1.959 0.283 2.814 C 73.335 65.239 72.76 65.49 72.181 65.49 z"
                                fill="currentColor"
                            />
                        </g>
                    </svg>
                </div>
                <h1 className="text-3xl font-bold text-gray-800">404 - Page Not Found</h1>
                <p className="text-gray-600">Sorry, the page you are looking for does not exist.</p>
                <Link to="/" className="inline-block mt-4 px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700">
                    Go to Home
                </Link>
            </div>
        </div>
    );
};

export default NotFoundPage;